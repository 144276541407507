import React from 'react';
export default ({ color = '#3f51b5' }) => (
  <svg
    version='1.1'
    id='Layer_1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    x='0px'
    y='0px'
    width='120px'
    height='120px'
    viewBox='0 0 120 120'
    enableBackground='new 0 0 120 120'
    xmlSpace='preserve'
  >
    <g>
      <path
        fill={color}
        d='M23.746,25.185c17.562-4.042,35.919-3.444,53.245,1.791c1.025,0.309,2.358-0.309,2.672-1.258
		c0.313-0.95-0.44-2.248-1.515-2.571c-17.995-5.437-37.06-6.057-55.299-1.86c-0.337-1.462-0.673-2.924-1.009-4.386
		c-0.137-0.59-0.389-1.095-1.083-1.17c-0.693-0.074-1.117,0.163-1.499,0.702c-1.78,2.512-3.427,5.066-4.938,7.649
		c-0.442,0.758-0.141,1.659,0.646,2.021c2.688,1.24,5.274,2.584,7.751,4.021c0.531,0.308,0.95,0.337,1.453-0.021
		c0.502-0.358,0.496-0.916,0.36-1.507C24.27,27.458,24.008,26.322,23.746,25.185z'
      />
      <path
        fill={color}
        d='M96.15,54.501c-0.83-2.276-3.207-3.806-5.918-3.806c-0.775,0-1.525,0.129-2.227,0.385
		c-0.381,0.139-1.305,0.625-2.189,1.298c-1.125-2.483-3.625-4.117-6.379-4.117c-0.816,0-1.621,0.142-2.395,0.423
		c-1.516,0.552-2.768,1.585-3.592,2.947c-1.74-2.22-4.627-3.147-7.316-2.169c-1.756,0.639-3.123,1.921-3.852,3.61l-6.977-17.229
		c-1.125-3.09-3.771-5.086-6.74-5.086c-0.805,0-1.615,0.146-2.406,0.434c-3.752,1.367-5.479,5.427-4.006,9.474l11.598,29.859
		c-0.609,0.689-1.486,1.862-2.473,3.738c-3.414,6.493-1.002,18.316,7.918,24.898c6.377,4.707,12.256,6.898,18.502,6.898
		c3.66,0,7.471-0.738,11.645-2.259c11.869-4.319,13.672-11.72,14.5-18.929C104.643,77.936,96.498,55.455,96.15,54.501z
		 M100.863,84.529c-0.781,6.788-2.264,12.711-12.545,16.453c-3.842,1.398-7.314,2.078-10.619,2.078
		c-5.65,0-10.807-1.947-16.721-6.312c-5.326-3.93-10.719-14.1-7.043-21.089c0.396-0.755,0.764-1.366,1.088-1.855l3.789,9.751
		c0.299,0.773,1.168,1.154,1.941,0.855c0.771-0.3,1.154-1.169,0.855-1.941L44.963,39.61c-0.906-2.489,0.008-4.792,2.223-5.598
		c2.186-0.794,4.424,0.448,5.32,2.909l10.518,25.97c0.287,0.713,1.068,1.094,1.811,0.877c0.738-0.215,1.193-0.955,1.055-1.711
		l-0.826-4.489c-0.016-0.083-0.037-0.163-0.066-0.242c-0.377-1.035-0.361-2.122,0.041-3.06c0.396-0.926,1.15-1.63,2.125-1.984
		c1.949-0.71,3.883,0.389,4.672,2.556l1.027,2.818v0c0.283,0.778,1.145,1.18,1.922,0.896s1.18-1.144,0.896-1.922l-0.002-0.007
		c-0.363-1.002-0.314-2.085,0.135-3.052c0.451-0.968,1.254-1.703,2.256-2.068c0.443-0.161,0.904-0.242,1.369-0.242
		c1.676,0,3.186,1.059,3.76,2.633c0,0,2.021,5.026,2.465,6.109s1.146,1.255,1.896,0.974s1.018-1.054,0.68-2.003
		c-0.336-0.95-1.305-3.586-1.305-3.586c0.463-0.48,1.436-1.248,2.1-1.489c0.371-0.135,0.773-0.204,1.199-0.204
		c1.432,0,2.705,0.754,3.1,1.833C95.629,61.839,101.459,79.353,100.863,84.529z'
      />
    </g>
  </svg>
);
