import React from 'react';
export default ({ color = '#3f51b5' }) => (
  <svg
    version='1.1'
    id='Layer_1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    x='0px'
    y='0px'
    width='120px'
    height='100px'
    viewBox='0 0 120 100'
    enableBackground='new 0 0 120 100'
    xmlSpace='preserve'
  >
    <path
      fill={color}
      d='M82.482,48.875c-0.407,0-1.44,0.14-2.502,0.47c-0.271-3.613-3.299-6.47-6.98-6.47
	c-1.657,0-3.182,0.579-4.382,1.545c-0.887-2.708-3.232-4.545-6.136-4.545c-1.923,0-3.634,0.791-4.854,2.083l-0.067-1.875
	c6.717-3.081,11.403-9.848,11.403-17.708c0-10.752-8.748-19.5-19.5-19.5s-19.5,8.748-19.5,19.5c0,8.607,5.613,15.907,13.366,18.485
	l0.312,14.572c-0.808,0.439-2.034,1.242-3.603,2.667c-5.429,4.934-7.206,16.868-1.075,26.105
	c6.825,10.282,14.822,14.671,26.742,14.671c12.629,0,16.854-6.338,20.1-12.83c3.122-6.244,3.158-30.156,3.158-31.17
	C88.965,51.678,85.936,48.875,82.482,48.875z M42.957,23.407l0.156,7.291c-2.512-1.919-4.148-4.926-4.148-8.323
	c0-5.79,4.71-10.5,10.5-10.5s10.5,4.71,10.5,10.5c0,2.732-1.064,5.207-2.783,7.075l-0.217-6.075c0-4.276-3.011-7.5-7.004-7.5
	S42.957,19.099,42.957,23.407z M32.965,22.375c0-9.098,7.402-16.5,16.5-16.5s16.5,7.402,16.5,16.5
	c0,6.202-3.447,11.605-8.521,14.422l-0.123-3.455c3.414-2.452,5.644-6.453,5.644-10.968c0-7.444-6.056-13.5-13.5-13.5
	s-13.5,6.056-13.5,13.5c0,5.176,2.938,9.662,7.226,11.924l0.071,3.346C37.231,35.187,32.965,29.277,32.965,22.375z M83.123,84.703
	c-3.056,6.112-6.475,11.172-17.416,11.172c-10.936,0-17.959-3.862-24.242-13.33c-3.661-5.516-5.25-16.916,0.593-22.227
	c0.631-0.573,1.188-1.022,1.66-1.371l0.224,10.459c0.018,0.828,0.683,1.507,1.532,1.468c0.828-0.018,1.485-0.703,1.468-1.532
	l-0.984-45.967c0-2.649,1.646-4.5,4.004-4.5s4.004,1.851,4.005,4.554l1,28c0.027,0.77,0.633,1.393,1.4,1.443
	c0.76,0.047,1.449-0.488,1.578-1.247l0.759-4.5c0.014-0.083,0.021-0.166,0.021-0.25c0-2.28,1.616-4,3.759-4
	c2.417,0,3.518,2.073,3.518,4v3c0,0.829,0.672,1.5,1.5,1.5s1.5-0.672,1.5-1.5c0-2.206,1.794-4,4-4s4,1.794,4,4v6.5
	c0,0.828,0.672,1.5,1.5,1.5s1.5-0.672,1.5-1.5v-3.82c0.6-0.292,1.776-0.68,2.482-0.68c1.79,0,3.482,1.457,3.482,3
	C85.965,61.591,85.453,80.043,83.123,84.703z'
    />
  </svg>
);
