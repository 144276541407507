import React from 'react';
export default ({ color = '#3f51b5' }) => (
  <svg
    version='1.1'
    id='Layer_1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    x='0px'
    y='0px'
    width='120px'
    height='100px'
    viewBox='0 0 120 100'
    enableBackground='new 0 0 120 100'
    xmlSpace='preserve'
  >
    <path
      fill={color}
      d='M81.482,45.875c-0.407,0-1.44,0.14-2.502,0.47c-0.271-3.613-3.299-6.47-6.98-6.47
	c-1.657,0-3.182,0.579-4.382,1.545c-0.887-2.708-3.232-4.545-6.136-4.545c-1.923,0-3.634,0.791-4.854,2.083l-0.308-8.615
	c3.414-2.452,5.644-6.453,5.644-10.968c0-7.444-6.056-13.5-13.5-13.5s-13.5,6.056-13.5,13.5c0,5.176,2.938,9.662,7.226,11.924
	l0.452,21.133c-0.808,0.439-2.034,1.242-3.603,2.667c-5.429,4.934-7.206,16.868-1.075,26.105
	c6.825,10.282,14.822,14.671,26.742,14.671c12.629,0,16.854-6.338,20.1-12.83c3.122-6.244,3.158-30.156,3.158-31.17
	C87.965,48.678,84.936,45.875,81.482,45.875z M82.123,81.703c-3.056,6.112-6.475,11.172-17.416,11.172
	c-10.936,0-17.959-3.862-24.242-13.33c-3.661-5.516-5.25-16.916,0.593-22.227c0.631-0.573,1.188-1.022,1.66-1.371l0.224,10.459
	c0.018,0.828,0.711,1.507,1.532,1.468c0.828-0.018,1.485-0.703,1.468-1.532l-0.984-45.967c0-2.649,1.646-4.5,4.004-4.5
	s4.004,1.851,4.005,4.554l1,28c0.027,0.769,0.633,1.393,1.4,1.443c0.752,0.047,1.449-0.488,1.578-1.247l0.759-4.5
	c0.014-0.083,0.021-0.166,0.021-0.25c0-2.28,1.616-4,3.759-4c2.417,0,3.518,2.073,3.518,4v3c0,0.829,0.672,1.5,1.5,1.5
	s1.5-0.671,1.5-1.5c0-2.206,1.794-4,4-4s4,1.794,4,4v6.5c0,0.828,0.672,1.5,1.5,1.5s1.5-0.672,1.5-1.5v-3.82
	c0.6-0.292,1.776-0.681,2.482-0.681c1.79,0,3.482,1.458,3.482,3C84.965,58.591,84.453,77.043,82.123,81.703z'
    />
  </svg>
);
