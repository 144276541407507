import React from 'react';
export default ({ color = '#3f51b5' }) => (
  <svg
    version='1.1'
    id='Layer_1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    x='0px'
    y='0px'
    width='120px'
    height='120px'
    viewBox='0 0 120 120'
    enableBackground='new 0 0 120 120'
    xmlSpace='preserve'
  >
    <g>
      <path
        fill={color}
        d='M21.006,83.285c-1.137,0.262-2.273,0.523-3.41,0.785c-4.042-17.562-3.444-35.918,1.79-53.245
		c0.309-1.025-0.309-2.358-1.258-2.672c-0.95-0.313-2.248,0.441-2.57,1.515c-5.438,17.995-6.058,37.06-1.86,55.298
		c-1.462,0.338-2.924,0.674-4.386,1.01c-0.59,0.137-1.096,0.389-1.17,1.082c-0.073,0.693,0.164,1.117,0.702,1.5
		c2.513,1.779,5.066,3.426,7.649,4.938c0.759,0.443,1.658,0.141,2.021-0.646c1.24-2.688,2.584-5.273,4.021-7.75
		c0.308-0.531,0.336-0.951-0.021-1.453C22.155,83.143,21.598,83.15,21.006,83.285z'
      />
      <path
        fill={color}
        d='M100.843,49.553c-4.799-5.07-27.257-13.282-28.211-13.629c-0.577-0.21-1.187-0.316-1.812-0.316
		c-2.584,0-5.126,1.832-6.045,4.356c-0.139,0.382-0.359,1.401-0.412,2.511c-0.616-0.173-1.25-0.261-1.89-0.261
		c-2.932,0-5.575,1.852-6.578,4.607c-0.552,1.516-0.566,3.139-0.052,4.644c-2.916-0.098-5.39,1.533-6.364,4.213
		c-0.64,1.755-0.536,3.626,0.289,5.27L32.087,55.22c-0.946-0.344-1.913-0.519-2.874-0.519c-3.033,0-5.551,1.737-6.57,4.535
		c-1.364,3.752,0.636,7.685,4.684,9.158l30.327,10.31c0.138,0.909,0.473,2.336,1.275,4.297c2.128,5.197,9.752,10.574,18.825,10.574
		c1.799,0,3.594-0.214,5.337-0.635c11.998-2.896,18.856-8.91,22.933-20.111C110.343,60.961,105.832,54.823,100.843,49.553z
		 M103.205,71.802c-3.74,10.276-9.771,15.556-20.818,18.222c-1.513,0.365-3.071,0.551-4.633,0.551
		c-7.71,0-14.334-4.521-16.049-8.711c-0.323-0.789-0.556-1.465-0.722-2.027l9.905,3.366c0.784,0.265,1.636-0.153,1.902-0.938
		s-0.152-1.636-0.938-1.903L28.321,65.563c-2.489-0.905-3.665-3.086-2.859-5.301c0.786-2.159,3.056-3.145,5.65-2.206l26.653,8.637
		c0.729,0.236,1.524-0.118,1.835-0.822c0.31-0.705,0.036-1.529-0.633-1.91l-3.97-2.252c-0.073-0.041-0.149-0.076-0.228-0.104
		c-1.035-0.376-1.858-1.085-2.318-1.996c-0.453-0.899-0.508-1.93-0.154-2.904c0.52-1.426,1.719-2.244,3.291-2.244
		c0.55,0,1.112,0.103,1.672,0.307l2.812,1.023c0.002,0.001,0.005,0.001,0.007,0.002c0.778,0.284,1.639-0.118,1.922-0.897
		c0.283-0.778-0.118-1.639-0.896-1.922c-1.004-0.365-1.805-1.1-2.257-2.068c-0.451-0.969-0.499-2.055-0.134-3.058
		c0.573-1.575,2.084-2.634,3.76-2.634c0.465,0,0.925,0.082,1.368,0.243l6.107,2.223c0.774,0.282,1.639-0.117,1.922-0.896
		c0.283-0.779-0.118-1.64-0.896-1.923l-3.588-1.306c-0.07-0.663-0.032-1.902,0.208-2.565c0.588-1.612,2.575-2.769,4.012-2.246
		c6.311,2.297,23.475,9.087,27.059,12.873C103.362,56.578,106.946,61.521,103.205,71.802z'
      />
    </g>
  </svg>
);
