import React from 'react';
export default ({ color = '#3f51b5' }) => (
  <svg
    version='1.1'
    id='Layer_1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    x='0px'
    y='0px'
    width='120px'
    height='120px'
    viewBox='0 0 120 120'
    enableBackground='new 0 0 120 120'
    xmlSpace='preserve'
  >
    <g>
      <path
        fill={color}
        d='M92.68,19.083c-1.512-2.583-3.158-5.137-4.938-7.649c-0.382-0.539-0.806-0.775-1.499-0.702
		c-0.694,0.074-0.946,0.58-1.083,1.17c-0.336,1.462-0.672,2.924-1.009,4.386c-18.239-4.197-37.304-3.577-55.299,1.86
		c-1.073,0.323-1.828,1.621-1.515,2.571c0.313,0.949,1.646,1.566,2.672,1.258c17.326-5.234,35.683-5.832,53.245-1.791
		c-0.262,1.137-0.523,2.274-0.785,3.411c-0.136,0.592-0.143,1.149,0.36,1.507s0.922,0.33,1.453,0.021
		c2.477-1.437,5.062-2.781,7.751-4.021C92.82,20.741,93.122,19.841,92.68,19.083z'
      />
      <path
        fill={color}
        d='M81.003,67.365c-0.846-0.308-1.872-0.394-2.51-0.416c0.453-1.611,0.317-3.312-0.397-4.845
		c-0.79-1.695-2.192-2.98-3.949-3.62c-1.51-0.548-3.185-0.547-4.645-0.049c0.033-0.953-0.118-1.884-0.452-2.742
		c-0.662-1.696-1.998-2.984-3.761-3.625c-1.769-0.643-3.697-0.504-5.268,0.281l5.726-17.673c1.462-4.018-0.265-8.077-4.016-9.444
		c-0.792-0.288-1.602-0.434-2.407-0.434c-2.97,0-5.615,1.997-6.75,5.117L42.266,60.239c-0.914,0.137-2.331,0.473-4.299,1.278
		c-6.79,2.781-12.541,13.389-9.938,24.163c2.896,11.998,8.91,18.856,20.111,22.933c3.145,1.145,6.1,1.725,8.781,1.725
		c6.644,0,10.803-3.411,14.493-6.905c5.07-4.8,13.282-27.257,13.629-28.212C86.136,72.217,84.249,68.546,81.003,67.365z
		 M82.225,74.195c-2.298,6.312-9.088,23.476-12.873,27.059c-3.387,3.207-6.886,6.084-12.431,6.084c-2.331,0-4.94-0.52-7.756-1.544
		c-10.275-3.739-15.555-9.771-18.221-20.817c-1.554-6.434,0.852-17.689,8.159-20.683c0.792-0.324,1.467-0.557,2.029-0.724
		l-3.367,9.907c-0.267,0.784,0.153,1.637,0.938,1.903c0.785,0.266,1.637-0.154,1.902-0.938l14.798-43.533
		c0.873-2.399,3.126-3.652,5.302-2.859c2.215,0.807,3.129,3.109,2.206,5.65l-8.637,26.653c-0.237,0.732,0.118,1.525,0.822,1.835
		c0.706,0.311,1.529,0.037,1.909-0.633l2.252-3.969c0.041-0.073,0.076-0.149,0.105-0.228c0.758-2.086,2.9-3.2,4.899-2.472
		c0.954,0.347,1.644,1.003,1.992,1.896c0.354,0.907,0.334,1.996-0.055,3.066l-1.023,2.812c-0.001,0.002-0.002,0.004-0.002,0.006
		c-0.283,0.779,0.118,1.64,0.896,1.923c0.779,0.283,1.639-0.118,1.922-0.897c0.573-1.574,2.084-2.633,3.76-2.633
		c0.465,0,0.925,0.082,1.367,0.243c1.004,0.365,1.806,1.101,2.257,2.068s0.499,2.055,0.134,3.059l-2.223,6.108
		c-0.283,0.777,0.118,1.639,0.896,1.922c0.774,0.283,1.638-0.117,1.922-0.896l1.306-3.586c0.173-0.02,0.405-0.035,0.698-0.035
		c0.72,0,1.471,0.097,1.868,0.241C81.66,70.797,82.752,72.746,82.225,74.195z'
      />
    </g>
  </svg>
);
