export default {
  DARK: '#99A799',
  RED: '#E2C2B9',
  YELLOW: '#F2DDC1',
  GREEN: '#D3E4CD',
  BACK_GROUND: '#F5EEDC',
  BROWN: '#8E806A',
  BLUE: '#406882',
  BRIGHT_RED: '#e2492a',
  TEXT: '#30475e',
};
